<mat-sidenav-container class="container">
  <mat-sidenav #filterNav mode="side" opened="false" position="end" class="filter-sidenav">
    <mat-list style="margin-top: 15px;">
      <mat-list-item>
        <filter-input [(filtered)]="accounts.filter.created"
                      (filterCleared)="accounts.clearFilter('created')"
                      label="Created" type="boolean"></filter-input>
      </mat-list-item>
      <mat-list-item>
        <filter-input [(filtered)]="accounts.filter.approved_and_created"
                      (filterCleared)="accounts.clearFilter('approved_and_created')"
                      label="Approved & Created" type="boolean"></filter-input>
      </mat-list-item>
      <mat-list-item>
        <filter-input [(filtered)]="accounts.filter.response"
                      (filterCleared)="accounts.clearFilter('response')"
                      [choices]="responses | async" value_field="id" field_name="name"
                      label="Response / Project" type="choices"></filter-input>
      </mat-list-item>
      <mat-list-item class="filter-btn">
        <button type="button" (click)="search()" mat-raised-button
                color="primary">
          Apply
        </button>
        <button type="button" (click)="clearAllFilters()" mat-raised-button color="accent">
          Clear All
        </button>
      </mat-list-item>
    </mat-list>
  </mat-sidenav>
  <div class="sidenav-content">

    <div class="list-header" style="display: flex; flex-direction: row; justify-content: space-between">
      <mat-form-field style="width:250px; padding-left: 25px; padding-top: 15px;">
        <input [formControl]="searchInput" matInput type="text" placeholder="Search" id="searchInput"/>
        <button style="position:absolute; top:-5px; right:0px;" type="button" mat-icon-button
                (click)="clearSearch()"
                *ngIf="accounts.filter.search">
          <mat-icon>close</mat-icon>
        </button>
      </mat-form-field>
      <!--Action Buttons-->

      <div style="display: flex; flex-direction: row; align-items: center;
                  justify-content: space-evenly; padding-right: 25px;">
        <ng-container *ngIf="selectedAccountIds.length > 0 && !isApprovalReady">
          <mat-icon style="color: red; margin-right: 5px">warning</mat-icon> Resolve error in order to approve this record.
        </ng-container>
        <div>
          <button *ngIf="selectedAccountIds.length > 0" type="button" mat-raised-button color="warn"
                  (click)="editAccountDialog()" style="margin-right: 20px; margin-left: 20px;" id="editBtn">
            Edit
          </button>
          <button *ngIf="isApprovalReady" type="button" mat-raised-button color="warn"
                  (click)="openApproveOptions()" style="margin-right: 20px;" id="approveBtn">
            Approve
          </button>
          <button type="button" mat-raised-button (click)="filterNav.toggle()" color="primary"
                  id="filterBtn">
            {{ filterNav.opened ? 'Hide Filter' : 'Show Filter' }}
          </button>
        </div>
      </div>
    </div>

    <div class="list-container">
      <mat-table #table [dataSource]="accounts.datasource"
                 class="table" matSort matSortActive="date_received" matSortDirection="desc"
                 (matSortChange)="accounts.sortTable($event)">
        <!--Account Select Checkbox-->
        <ng-container cdkColumnDef="selected">
          <mat-header-cell *cdkHeaderCellDef class="header-cell" matTooltip="Select all records for approval">
            <mat-checkbox (change)="updateSelectedAccount($event, true)"
                          [checked]="allChecked"></mat-checkbox>
          </mat-header-cell>
          <mat-cell *cdkCellDef="let row" class="cell" matTooltip="Select record(s) to approve">
            <mat-checkbox (change)="updateSelectedAccount($event)" [value]="row.id" *ngIf="accountsListProps[row.id]"
                          [checked]="accountsListProps[row.id].isChecked"></mat-checkbox>
          </mat-cell>
        </ng-container>
        <!--Name-->
        <ng-container cdkColumnDef="first_name">
          <mat-header-cell *cdkHeaderCellDef mat-sort-header class="header-cell">
            First Name
          </mat-header-cell>
          <mat-cell *cdkCellDef="let row" class="cell">{{ row.first_name }}</mat-cell>
        </ng-container>
        <ng-container cdkColumnDef="last_name">
          <mat-header-cell *cdkHeaderCellDef mat-sort-header class="header-cell">
            Last Name
          </mat-header-cell>
          <mat-cell *cdkCellDef="let row" class="cell">{{ row.last_name }}</mat-cell>
        </ng-container>
        <!-- Username -->
        <ng-container cdkColumnDef="username">
          <mat-header-cell *cdkHeaderCellDef class="header-cell">
            Username
          </mat-header-cell>
          <mat-cell *cdkCellDef="let row" class="cell">
            <mat-icon style="color: red;"
                      *ngIf="row.is_existing_account && !emailMatchesAccount(row) && !row.username_valid"
                      [matTooltip]="getEmailMismatchTooltip(userConfig)">
              warning
            </mat-icon>
            <mat-icon color="warn"
                      *ngIf="row.is_existing_account && !row.username_valid && row.existing_account_enabled"
                      matTooltip="Username already exists. Approval will modify existing groups.">
              warning
            </mat-icon>
            <mat-icon style="color: red;"
                      *ngIf="row.is_existing_account && emailMatchesAccount(row) && !row.existing_account_enabled"
                      matTooltip="Username already exists but account is disabled. Approval will re-enable account and modify existing groups.">
              warning
            </mat-icon>
            <mat-icon style="color: red;" *ngIf="!row.is_existing_account && !row.username_valid "
                      matTooltip="Username is invalid or unavailable.  Please edit.">
              warning
            </mat-icon>
            {{ row.username }}
          </mat-cell>
        </ng-container>
        <!-- Email -->
        <ng-container cdkColumnDef="email">
          <mat-header-cell *cdkHeaderCellDef mat-sort-header class="header-cell">
            Email
          </mat-header-cell>
          <mat-cell *cdkCellDef="let row" class="cell">
            <mat-icon color="warn" *ngIf="row.possible_existing_account.length > 0" tooltipClass="'mat-tooltip'"
                      matTooltip="Found accounts with same email. To add existing account to groups, edit Username to an existing username.
                      Existing username(s): {{ row.possible_existing_account }}">
              warning
            </mat-icon>
            {{ row.email }}</mat-cell>
        </ng-container>
        <!-- Organization -->
        <ng-container cdkColumnDef="organization">
          <mat-header-cell *cdkHeaderCellDef mat-sort-header class="header-cell">
            Organization
          </mat-header-cell>
          <mat-cell *cdkCellDef="let row" class="cell">{{ row.organization }}</mat-cell>
        </ng-container>
        <!-- Role -->
        <!--        <ng-container cdkColumnDef="role">-->
        <!--          <mat-header-cell *cdkHeaderCellDef mat-sort-header class="header-cell">-->
        <!--            Role-->
        <!--          </mat-header-cell>-->
        <!--          <mat-cell *cdkCellDef="let row" class="cell">-->
        <!--            <mat-select [(ngModel)]="row.role" (selectionChange)="row.changed = true">-->
        <!--              <mat-option *ngFor="let role of roles | async" [value]="role.value">-->
        <!--                {{ role.display }}-->
        <!--              </mat-option>-->
        <!--            </mat-select>-->
        <!--          </mat-cell>-->
        <!--        </ng-container>-->
        <!-- Groups -->
        <ng-container cdkColumnDef="groups">
          <mat-header-cell *cdkHeaderCellDef mat-sort-header class="header-cell">
            Groups
          </mat-header-cell>
          <mat-cell *cdkCellDef="let row" class="cell">
            <mat-icon *ngIf="row.groups.length > 0" style="color: green;">check</mat-icon>
            <mat-icon *ngIf="row.groups.length == 0" style="color: red;"
                      matTooltip="No group has been selected.">warning
            </mat-icon>
          </mat-cell>
        </ng-container>
        <!-- sponsor -->
        <ng-container cdkColumnDef="sponsor">
          <mat-header-cell *cdkHeaderCellDef class="header-cell">
            Sponsor
          </mat-header-cell>
          <mat-cell *cdkCellDef="let row" class="cell">
            <mat-icon *ngIf="row.sponsor" style="color: green;">check</mat-icon>
            <mat-icon *ngIf="!row.sponsor" style="color: red;"
                      matTooltip="No sponsor has been selected.">warning
            </mat-icon>
          </mat-cell>
        </ng-container>
        <!-- response -->
        <ng-container cdkColumnDef="response">
          <mat-header-cell *cdkHeaderCellDef class="header-cell">
            Response
          </mat-header-cell>
          <mat-cell *cdkCellDef="let row" class="cell">
            <mat-icon *ngIf="row.response" style="color: green;">check</mat-icon>
            <mat-icon *ngIf="!row.response" color="warn"
                      tooltipClass="'mat-tooltip'"
                      matTooltip="No response has been selected.">warning
            </mat-icon>
          </mat-cell>
        </ng-container>
        <!-- Reason -->
        <ng-container cdkColumnDef="reason">
          <mat-header-cell *cdkHeaderCellDef mat-sort-header class="header-cell">
            Reason
          </mat-header-cell>
          <mat-cell *cdkCellDef="let row" class="cell">
            <mat-icon *ngIf="row.reason" style="color: green;">check</mat-icon>
            <mat-icon *ngIf="!row.reason" color="warn"
                      tooltipClass="'mat-tooltip'"
                      matTooltip="No reason has been provided.">warning
            </mat-icon>
          </mat-cell>
        </ng-container>
        <!-- User Types -->
        <ng-container cdkColumnDef="user_type">
          <mat-header-cell *cdkHeaderCellDef mat-sort-header class="header-cell">
            User Type
          </mat-header-cell>
          <mat-cell *cdkCellDef="let row" class="cell">
            <mat-select [(ngModel)]="row.user_type" (selectionChange)="row.changed = true">
              <mat-option *ngFor="let user_type of user_types | async" [value]="user_type.value">
                {{ user_type.display }}
              </mat-option>
            </mat-select>
          </mat-cell>
        </ng-container>
        <!-- Approved -->
        <ng-container cdkColumnDef="approved">
          <mat-header-cell *cdkHeaderCellDef mat-sort-header class="header-cell">
            Approved
          </mat-header-cell>
          <mat-cell *cdkCellDef="let row" class="cell">
            <mat-icon *ngIf="row.approved" style="color: green;">check</mat-icon>
            <mat-icon *ngIf="!row.approved" style="color: red;">close</mat-icon>
          </mat-cell>
        </ng-container>
        <!--Created-->
        <ng-container cdkColumnDef="created">
          <mat-header-cell *cdkHeaderCellDef mat-sort-header class="header-cell">
            Created
          </mat-header-cell>
          <mat-cell *cdkCellDef="let row" class="cell">
            <mat-icon *ngIf="row.created" style="color: green;">check</mat-icon>
            <mat-icon *ngIf="!row.created" style="color: red;">close</mat-icon>
          </mat-cell>
        </ng-container>
        <!--Delete-->
        <ng-container cdkColumnDef="delete">
          <mat-header-cell *cdkHeaderCellDef class="header-cell">
            Delete
          </mat-header-cell>
          <mat-cell *cdkCellDef="let row" class="cell">
            <button *ngIf="!row.approved" mat-icon-button (click)="confirmDeleteAccountRequest($event, row)"
                    [matTooltip]="!row.approved ? 'Delete unapproved request' : ''">
              <mat-icon style="color: red;">delete_forever</mat-icon>
            </button>
          </mat-cell>
        </ng-container>

        <mat-header-row *cdkHeaderRowDef="displayedColumns" class="header-row"></mat-header-row>
        <mat-row *cdkRowDef="let row; columns: displayedColumns;" class="row"></mat-row>
      </mat-table>
      <mat-paginator [length]="accounts.count" [pageSize]="25"
                     [pageSizeOptions]="[1, 10, 25, 50, 100]"
                     (page)="getPage($event)"
                     [pageIndex]="accounts.filter.page-1"></mat-paginator>

    </div>
  </div>
</mat-sidenav-container>
