<h1 mat-dialog-title>Please confirm
  <span *ngIf="data.password_needed">and provide initial password for these accounts.</span></h1>
<div mat-dialog-content>
  <p class="mat-body-1" *ngIf="data.password_needed">
    Password Requirements<br/>
    1 Letter<br/>
    1 Number<br/>
    13 Characters Long
  </p>
  <form novalidate [formGroup]="passwordForm" *ngIf="data.password_needed">
    <mat-form-field style="width: 100%">
      <input matInput required placeholder="Initial Password" formControlName="passwordInput" type="password">

      <mat-error *ngIf="passwordForm.controls.passwordInput.invalid">
        {{ passwordForm.controls.passwordInput.hasError('pattern') ? 'Does not meet complexity requirements' : '' }}

      </mat-error>
    </mat-form-field>
    <mat-form-field style="width: 100%; padding-top: 10px;">
      <input matInput required placeholder="Confirm Password" formControlName="passwordConfirm" type="password">
    </mat-form-field>
      <mat-error *ngIf="!passwordForm.valid" class="mat-body-2">
        {{ passwordForm.hasError('passwordMatch') ? 'Passwords do not match' : '' }}
      </mat-error>
  </form>
</div>
<div mat-dialog-actions>
  <button mat-raised-button color="accent" (click)="dismiss()">Cancel</button>
  <button mat-raised-button
          color="warn" (click)="confirm()" [disabled]="!passwordForm.valid" *ngIf="data.password_needed">Approve
  </button>
  <button mat-raised-button color="warn" (click)="confirm()" *ngIf="!data.password_needed">Approve</button>
</div>
