<!--<mat-card style="flex-basis: 500px; height: 100%">-->
<!--  <mat-card-title>Response / Project Request Form</mat-card-title>-->
<!--  <mat-card-subtitle>Use the form below to request a new emergency response or project be added to the tool.-->
<!--  </mat-card-subtitle>-->
<h4 *ngIf="userConfig.config | async as c" style="align-self: self-start;">
  <span *ngIf="c.is_sponsor">You were automatically included as a team coordinator.</span>
  <span *ngIf="!c.is_sponsor && !c.is_delegate">
    You are not currently registered as a team coordinator or delegate.
    Please continue and you will be contacted for verification.
  </span>
  <span *ngIf="c.is_delegate && c.delegate_for.length > 1">
    The sponsors for which you are a delegate were automatically included as team coordinators.
  </span>
  <span *ngIf="c.is_delegate && c.delegate_for.length === 1">
    The sponsor for which you are a delegate was automatically included as a team coordinator.
  </span>
  <br/><br/>
  This request will be for the {{c.portal}}.  If that is incorrect login with the preferred system.
</h4>
<form novalidate [formGroup]="fieldTeamCoordErForm">
  <!--Emergency Response Name-->
  <mat-form-field>
    <input matInput required type="text" formControlName="name"
           placeholder="Response or Project Name">
  </mat-form-field>
  <!-- reason -->
  <mat-form-field>
    <mat-label>Reason / Affiliation</mat-label>
    <mat-select required formControlName="default_reason" placeholder="Reason / Affiliation">
      <mat-option *ngFor="let r of reasons | async" [value]="r.value">
        {{r.display_name}}
      </mat-option>
    </mat-select>
  </mat-form-field>
  <!--Field team coordinators-->
  <mat-form-field>
    <mat-label>Team Coordinators</mat-label>
    <mat-select formControlName="users" multiple>
      <mat-option *ngFor="let field_coordinator of field_coordinators | async"
                  [value]="field_coordinator.id">
            <span>
              <ng-container *ngIf="field_coordinator.last_name && field_coordinator.first_name; then nameBlock else emailBlock"></ng-container>
              <ng-template #nameBlock>{{field_coordinator.first_name + ' ' + field_coordinator.last_name}}</ng-template>
              <ng-template #emailBlock>{{field_coordinator.email}}</ng-template>
            </span>
      </mat-option>
    </mat-select>
  </mat-form-field>
  <!-- groups -->
  <app-tag-input label="Groups" (tagSelected)="fieldTeamCoordErForm.controls.assignable_groups.setValue($event)"
                 [tags]="this.tags" hint="Select all applicable groups.">
  </app-tag-input>
    <!-- role -->
  <mat-form-field>
    <mat-select required formControlName="role" placeholder="Role" (selectionChange)="getAuthGroups($event.value)">
      <mat-option *ngFor="let r of roles | async" [value]="r.id">
        {{r.name}}
      </mat-option>
    </mat-select>
  </mat-form-field>
  <!--authoritative group-->
  <mat-form-field *ngIf="(userConfig.config | async)?.portal_requires_auth_group">
    <mat-select formControlName="authoritative_group" placeholder="Primary Auth Group" aria-label="Primary Auth Group">
      <mat-option *ngFor="let authGroupChoice of auth_groups | async" [value]="authGroupChoice.id">
        {{authGroupChoice.title}}
      </mat-option>
    </mat-select>
  </mat-form-field>

</form>
<br>
<button mat-raised-button color="primary" [disabled]="!fieldTeamCoordErForm.valid" (click)="submit()">
  <span *ngIf="!(submitting | async)">Submit</span>
  <mat-spinner *ngIf="submitting | async" style="margin: 5px;" color="warn" diameter="30"></mat-spinner>
</button>
