<!--<h1 mat-dialog-title style="text-align: center">Please choose</h1>-->
<div mat-dialog-content>
  Existing account will be added to the selected groups. <span *ngIf="!data.existing_only">For new account requests choose setup method.</span>
  <br>

  <button mat-raised-button color="primary" (click)="dialogRef.close('invitation')">
    <span *ngIf="!data.existing_only">Send Email Invitation(s)</span>
    <span *ngIf="data.existing_only">Confirm Approval</span>
  </button>
  <button *ngIf="!data.existing_only" mat-raised-button color="primary" (click)="dialogRef.close('password')">Set Password Manually</button>

  <button mat-raised-button color="accent" (click)="dialogRef.close()" cdkFocusInitial>Cancel</button>

</div>

