<div style="max-width: 800px;">
  <div style="display: flex; flex-direction: column;  align-items: center; margin-bottom: 25px;" *ngIf="!(isResponseInURL | async)">
    <p>
      The Account Request Tool provisions EPA GIS user accounts during emergency response
      events and projects.
      Authorized users (Team Coordinators) can rapidly set up accounts for field data collection
      events and provide access to specific groups. Visit the <a href="./assets/AcctRequestUserGuide.pdf"
                                                                             target="_blank">Help Documentation</a> for
      additional information.
      <br>
    </p>
    <button mat-raised-button color="primary" (click)="openResponseRequestDialog()">
      Configure New Response / Project
    </button>
  </div>
  <app-request-form></app-request-form>
  <div style="display: flex; justify-content: center" *ngIf="isResponseInURL | async">
    <p>
      Can't find the Response / Project you are looking for? <a routerLink="" [queryParams]="{}">Click here for full list</a>
    </p>
  </div>
</div>

