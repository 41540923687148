<header>
  <mat-toolbar class="header" color="primary" style="justify-content: space-between">
    <div style="display: flex; align-items: center; cursor: pointer;" routerLink="/">
      <img src="./assets/EPA_logo.png" style="width: 60px;"/>
      <ng-container *ngIf="config | async as c">{{ c.portal }}</ng-container>
      Account Request Tool
    </div>
    <div style="padding-right: 25px;">
      <button mat-button routerLink="/accounts/list/" *ngIf="!(config | async)">Team Coordinator Login</button>
      <span *ngIf="config | async as c">
        <button mat-button id="userName" [matMenuTriggerFor]="menu">{{ c.name }}</button>
        <mat-menu #menu="matMenu">
          <button *ngIf="c.is_superuser || c.permissions.includes('view_accountrequests')" mat-menu-item
                  routerLink="/accounts/list/">
            Approval List
          </button>
          <a *ngIf="c && c.is_staff" style="text-decoration: none;" [href]="admin_url">
            <button mat-menu-item>Administration</button>
          </a>
          <button mat-menu-item (click)="logout()">Logout</button>
        </mat-menu>
      </span>
      <a href="assets/AcctRequestUserGuide.pdf" target="_blank"
         matTooltip="View Help Documentation" matTooltipClass="mat-tooltip-blue" matTooltipPosition="below">
        <mat-icon style="color:white; vertical-align:middle">help</mat-icon>
      </a>
    </div>
  </mat-toolbar>
</header>
<ng-container *ngIf="config | async as userConfig">
  <header *ngIf="route.snapshot.fragment && !((userConfig.portal|lowercase) === (route.snapshot.fragment|lowercase))"
          style="background-color: #eeeeee; display: flex;
    justify-content: center;
    align-items: center; padding: 12px;">
    <mat-icon style="vertical-align: text-bottom; transform: scale(1.5)" color="warn" role="img">warning</mat-icon>
    <span style="padding-left: 15px; padding-right: 15px; font-weight: bold">
      You have followed a link for {{ route.snapshot.fragment }}, would you like to log out and switch?
    </span>
    <button mat-raised-button color="warn" (click)="switch_portal()">Log Out</button>
    <button mat-mini-fab aria-label="Dismiss" matTooltip="Dismiss" style="margin-left: 15px" (click)="close_warning()">
      <mat-icon>close</mat-icon>
    </button>
  </header>
</ng-container>
<router-outlet></router-outlet>
