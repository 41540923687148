<div class="login-container">
  <div>
    <mat-card style="display: flex; flex-direction: column; align-items: center;">
      <div style="max-width: 400px; text-align: center;margin-bottom: 10px;">
        <ng-container *ngIf="showNewUserMessage">Please proceed to be able to submit a new response / project request.
        </ng-container>
        <ng-container *ngIf="!showNewUserMessage && !geoportal && !geosecure">Please select the environment in which you
          will be creating a response / project or approving requests.
        </ng-container>
      </div>
      <div *ngIf="!geosecure" style="padding-bottom: 10px">
        <button mat-raised-button color="primary" (click)="login('geoplatform')">Login using EPA GeoPlatform</button>
      </div>
      <div *ngIf="!geoportal">
        <button mat-raised-button color="primary" (click)="login('geosecure')">Login using EPA GeoSecure</button>
      </div>
    </mat-card>
  </div>
</div>
