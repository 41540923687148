<mat-form-field>
    <mat-select placeholder="{{ label }}" [(ngModel)]="filtered" (ngModelChange)="filteredChange.emit(filtered)">
        <ng-container *ngIf="type === 'boolean'">
            <mat-option [value]="true">
                True
            </mat-option>
            <mat-option [value]="false">
                False
            </mat-option>
        </ng-container>
        <ng-container *ngIf="type === 'choices'">
            <mat-option *ngFor="let choice of choices" [value]="choice[value_field ? value_field : field_name]">
                {{ choice[field_name] }}
            </mat-option>
        </ng-container>
    </mat-select>
    <button mat-button type="button" color="accent" *ngIf="filtered !== undefined"
            class="filter-clear-button" (click)="clearFilter($event)">
        <mat-icon>close</mat-icon>
    </button>
</mat-form-field>
